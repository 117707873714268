<template>
  <div class="flex-col">
    <div class="flex-row row-center">
      <div class="main-content-width" style="padding-bottom: 5rem" >
        <div style="padding-top: 5rem;padding-bottom: 5rem">
          <img src="../assets/images/home/title_aboutme.png"/>
        </div>
        <div class="flex-row">
          <div>
            <img :src="webConfig.aboutImg"/>
          </div>
          <div>
            <div style = "width:52rem; padding-top:9rem;text-align: left">
                <label v-html="webConfig.aboutTitle">
                  {{webConfig.aboutTitle}}
                </label>
              <p v-html="webConfig.aboutContent">
                &ensp;&ensp;&ensp;&ensp;{{webConfig.aboutContent}}
              </p>
              <div style="height:.1rem;width:10rem; border:0.1rem solid #f66320; margin-bottom: 5rem"></div>

              <router-link to="/about"><span class="moreMsgbtn" style="float: right;">了解更多信息</span></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row row-center">
      <div class="our-products">
        <div style="padding-top: 5rem;padding-bottom: 5rem">
          <img src="../assets/images/home/title_product.png"/>
        </div>
        <div class="padding-sm">
          <el-radio-group v-model="gameType" :fill="'#f66320'" :text-color="'#ffffff'" @change="changeHandler">
            <div class="flex-row row-center" >
              <div class="round-btn" style="margin-left: 0"><el-radio-button  label="1">手游</el-radio-button></div>
              <div class="round-btn"><el-radio-button  label="2" style="width: 100%">H5</el-radio-button></div>
              <div class="round-btn"><el-radio-button  label="3">其他</el-radio-button></div>
            </div>
          </el-radio-group>
        </div>
        <div class="main-content-width marign-center" style="padding: 2rem 0">
          <div class="products-mian" v-if="arrTable.length>0">
            <router-link v-for="item in arrTable" :key="item.id" :to="{path:'/product/productArticle',query:{id:item.id}}" style="text-decoration: none">
             <div :style="'background-image:url('+item.logoUrl+')' ">
               <img src="../assets/images/home/blackMask.png"/>
               <span class="homeProductTitle">{{item.title}}</span>
             </div>
            </router-link>
          </div>
          <router-link to="/product"><span class="moreMsgbtn-gray" style="float: right; margin: 2rem 0">&emsp;更多&emsp;</span></router-link>
        </div>
      </div>
    </div>

    <div class="flex-row row-center threeHight">
      <div>
        <div style="padding-top: 5rem;padding-bottom: 5rem">
          <img src="../assets/images/home/title_joinus.png"/>
        </div>

        <div class="main-centent-width">
          <div class="joinus_content">
            <router-link :to="{path:'/hire',query:{type:1}}">
               <img ref="cxy" class="lessenRate"  src="../assets/images/home/joinus_cxy_w.png" @mouseover="cxyHandler" @mouseleave="cxyHandler">
            </router-link>
            <router-link :to="{path:'/hire',query:{type:2}}">
               <img ref="sjs" class="lessenRate" src="../assets/images/home/joinus_sjs_w.png" @mouseover="sjsHandler" @mouseleave="sjsHandler">
            </router-link>
          </div>
          <router-link style="text-decoration:none" to="/hire">
          <div class="moreMsgbtn-joinus" @mouseover="joinusBtnHandler" @mouseleave="joinusBtnHandler">
            <img ref="moreBtnIcon" class="lessenRate" src="../assets/images/home/joinus_jt_orange.png">
            <div>了解更多</div>
          </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EventNameEnum from "../enum/EventName"

export default {
    name: 'Home',
  created(){
    this.btnUrlW =require("../assets/images/home/joinus_jt_white.png");
    this.btnUrlO = require("../assets/images/home/joinus_jt_orange.png");
    this.cxyUrlW = require("../assets/images/home/joinus_cxy_w.png");
    this.cxyUrlO = require("../assets/images/home/joinus_cxy_o.png");
    this.sjsUrlW = require("../assets/images/home/joinus_sjs_w.png");
    this.sjsUrlO = require("../assets/images/home/joinus_sjs_o.png");
    this.$event.$emit(EventNameEnum.changeBar,true);
    this.$http.get('product/getHomeShow',{},{})
            .then((res) => {
              if(res.body.code==200){
                // console.log(res.body.data)
                let data = res.body.data;
                this.h5Arr = data.h5;
                this.phoneArr = data.phoneGame;
                this.otherArr = data.other;
                //this.switchTable(this.gameType)
                this.initLogo();
                this.arrTable = this.phoneArr;
              }else{

              }
            }, (error) => {
              this.messaege.error("连接错误！");
            })
    this.$http.get('webConfig/getWebConfig',{params: {type:2}},{})
            .then((res) => {
              if(res.body.code==200){
                // console.log(res.body.data)
                for(let data of res.body.data){
                  this.webConfig[data.name] = data.content;
                }
              }else{

              }
            }, (error) => {
              this.messaege.error("连接错误！");
            })

  },
    data(){
        return {
            gameType: '1',
            btnUrlW:null,
            btnUrlO:null,
            webConfig:{
              aboutTitle:"关于我们标题",
              aboutContent:"关于我们内容",
              aboutImg:"关于我们图片"
            },
            h5Arr:[],
            phoneArr:[],
            otherArr:[],
            arrTable:[],
            cxyUrlW:null,
            cxyUrlO:null,
        }
    },
    methods:{
      joinusBtnHandler:function(e){
        if(e.type=="mouseover"){
          this.$refs.moreBtnIcon.src = this.btnUrlW;
        }else{
          this.$refs.moreBtnIcon.src = this.btnUrlO;
        }
      },
      cxyHandler(e){
        if(e.type=="mouseover"){
          this.$refs.cxy.src = this.cxyUrlO;
        }else{
          this.$refs.cxy.src = this.cxyUrlW;
        }
      },
      sjsHandler(e){
        if(e.type=="mouseover"){
          this.$refs.sjs.src = this.sjsUrlO;
        }else{
          this.$refs.sjs.src = this.sjsUrlW;
        }
      },
      switchTable(num){
        num = Number(num);
        switch (num) {
          case 1:return this.phoneArr;
          case 2:return this.h5Arr;
          case 3:return this.otherArr;
        }
      },
      changeHandler(e){
        this.arrTable = this.switchTable(e);
      },
      initLogo(){
        const ARRTYPE = ["phoneArr","h5Arr","otherArr"];
        for(let typeName of ARRTYPE){
           for(let item of this[typeName]){
             if(item.indexLogoUrl&&item.indexLogoUrl.length&&item.indexLogoUrl.trim()){
               item.logoUrl = item.indexLogoUrl;
             }
           }
        }
      }
    }
}
</script>

<style >
  .row-center {
    justify-content: center;
  }
  .row-end {
    justify-content: flex-end;
  }
  .our-products {
    width: 100%;
    background-image: url("../assets/images/home/product_bg.png");
    bakground-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .round-btn {
    border-radius: 2rem;
    /*border: 1px solid;*/
    overflow: hidden;
    width: 13rem;
    margin-left: 3rem;
  }

  .round-btn .el-radio-button .el-radio-button__inner:hover{
    color: #f66320;
  }

  .round-btn .el-radio-button .el-radio-button__inner {
    font-family: "微软雅黑";
    font-size: 1.7rem;
    width: 13rem;
    border-radius: 2rem !important;
    font-weight: bold;
  }


  .moreMsgbtn {
    font-size: 1.7rem;
    font-family: "微软雅黑";
    color: #f66320;
    font-weight: bold;
    border: 0.2rem solid #f66320;
    background: #FFFFFF;
    border-radius: 3rem;
    padding: 1.2rem 2.3rem;
  }

  .moreMsgbtn:hover{
    background-color: #f66320;
    color:#ffffff ;
  }

  .moreMsgbtn-gray {
    font-size: 1.7rem;
    font-family: "微软雅黑";
    color: #555555;
    font-weight: bold;
    border: 0.2rem solid #555555;
    /*background: #FFFFFF;*/
    border-radius: 3rem;
    padding: 1.2rem 2.3rem;
  }

  .moreMsgbtn-gray:hover{
    background-color: #555555;
    color:#ffffff ;
  }



  .products-mian{
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-content: center;*/
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 相当于 1fr 1fr 1fr */
    grid-template-rows: repeat(1, 1fr); /* fr单位可以将容器分为几等份 */
    /*grid-gap: 1%; !* grid-column-gap 和 grid-row-gap的简写 *!*/
    grid-auto-flow: row;
    margin: 0 auto;
  }

  .products-mian div{
    width: 28.9rem;
    height: 19.6rem;
    /*background-color: #b3450e;*/
    background-color:#555555;
    background-repeat:no-repeat;
    background-size:auto;
    background-position:center;
    /*background-size:100% 100%;*/
    margin: 0 1rem;
    border-radius: 2rem;
  }


  .joinus_content{
    width: 50rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .moreMsgbtn-gray {
    font-size: 1.7rem;
    font-family: "微软雅黑";
    color: #555555;
    font-weight: bold;
    border: 0.2rem solid #555555;
    /*background: #FFFFFF;*/
    border-radius: 3rem;
    padding: 1.2rem 2.3rem;
  }

  .moreMsgbtn-gray:hover{
    background-color: #555555;
    color:#ffffff ;
  }

  .moreMsgbtn-joinus {
    padding-top: 1.3rem;
    height: 7.7rem;
    width: 7.7rem;
    position: relative;
    left: 75rem;
    bottom:20rem;
    font-size: 1.1rem;
    font-family: "微软雅黑";
    color: #ffc995;
    font-weight: bold;
    border: 0.2rem solid #ffc995;
    /*background: #FFFFFF;*/
    border-radius: 5rem;
  }

  .moreMsgbtn-joinus:hover{
    background-color: #ffc995;
    color:#ffffff ;
  }

  .homeProductTitle{
    color:#ffffff;
    position:relative;
    left:3rem;
    bottom:2.5rem;
    font-size:1.4rem;
    font-family: "微软雅黑";
    text-align: left;
    display: block;
  }

  .lessenRate{
    max-width: 80%;
    max-height: 80%;
  }

</style>

<style scoped>
  .threeHight{
    height: 40rem;
  }

</style>
