<template>
    <div id="app">
        <div id="s-nav-top">
            <div class="nav-content main-content-width">
                <div class="flex-sub text-left logo">
                    <img src="./assets/images/public/logo.png"/>
                </div>
                <div class="flex-sub flex-row">
                    <router-link to="/">首页</router-link>
                    <router-link to="/about">关于我们</router-link>
                    <router-link to="/product">产品介绍</router-link>
                    <router-link to="/news">新闻资讯</router-link>
                    <router-link to="/hire">招贤纳士</router-link>
                    <router-link to="/contact">联系我们</router-link>
                </div>
            </div>
        </div>
        <div class="top-swipe" v-if="this.lookbar">
            <swiper v-if="webConfig.barIsPlayVideo=='0'" class="swiper" :options="mainSwiperOptions">
            <swiper-slide  v-for="item in webConfig.mainSwiper" :key="item.id"><img :src="item.imgUrl"/></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
                <!--<div class="swiper-pagination"></div>-->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>

            </swiper>
            <div v-if="webConfig.barIsPlayVideo=='1'" class="barVideo" :style="`background-image:url('${webConfig.videoBg}')`">
                <vue-core-video-player
                        style="width: 90rem;height: 45rem"
                        :src="webConfig.mainVideo"
                        :controls="true"
                        :loop="true"
                        :autoPlay = "true"
                ></vue-core-video-player>
            </div>
        </div>
        <router-view/>
        <div id="bottom">
            <div id="bottom-nav">
                <div>
                    <div>
                        <router-link to="/about">
                        <div class="bottom-icon" id="bottomicon1" @mouseover="bottomIconHandle" @mouseleave="bottomIconHandle" >
                            <img class="changeBottomBtnRate" src="./assets/images/public/bottom_gywm_o.png">
                        </div>
                        </router-link>
                    </div>
                    <div class="bottom-text">
                        <span>关于我们</span>
                    </div>
                </div>
                   <div style="width: 2rem"/>

                <div>
                    <div>
                        <router-link to="/product">
                        <div class="bottom-icon" id="bottomicon2" @mouseover="bottomIconHandle" @mouseleave="bottomIconHandle" >
                            <img class="changeBottomBtnRate" src="./assets/images/public/bottom_cpjs_o.png">
                        </div>
                        </router-link>
                    </div>
                    <div class="bottom-text">
                        <span>产品介绍</span>
                    </div>
                </div>
                <div style="width: 2rem"/>

                <div>
                    <div>
                        <router-link to="/hire">
                        <div class="bottom-icon" id="bottomicon3" @mouseover="bottomIconHandle" @mouseleave="bottomIconHandle" >
                            <img class="changeBottomBtnRate" src="./assets/images/public/bottom_jrwm_o.png">
                        </div>
                        </router-link>
                    </div>
                    <div class="bottom-text">
                        <span>加入我们</span>
                    </div>
                </div>
                <div style="width: 2rem"/>

                <div>
                    <div>
                        <router-link to="/contact">
                        <div class="bottom-icon" id="bottomicon4" @mouseover="bottomIconHandle" @mouseleave="bottomIconHandle" >
                            <img class="changeBottomBtnRate" src="./assets/images/public/bottom_lxwm_o.png">
                        </div>
                        </router-link>
                    </div>
                    <div class="bottom-text">
                        <span>联系我们</span>
                    </div>
                </div>
            </div>

            <div id="bottom-notice">
                <div class="notice-logo">
                    <img src="./assets/images/public/logo.png">
                </div>
                <div class="notice-word"><span>健康游戏公告：</span>{{webConfig.HealthyNotice}}<br>
                    <span>公司地址：</span>{{webConfig.Address}}<br>

                    <span>{{webConfig.copyright}}</span>&ensp;
                    <span class="not-underline hover-underline">
                        <router-link :to="{path:'/declaration',query:{type:1}}" target="_blank" v-if="this.declarationConfig[1].switchNum===1"><span>法律声明</span></router-link>&ensp;
                        <router-link :to="{path:'/declaration',query:{type:2}}" target="_blank" v-if="this.declarationConfig[2].switchNum===1"><span>隐私政策</span></router-link>&ensp;
                        <router-link :to="{path:'/declaration',query:{type:3}}" target="_blank" v-if="this.declarationConfig[3].switchNum===1"><span>家长监护</span></router-link>&ensp;
                    </span>&ensp;
                    <span>投诉电话：</span>{{webConfig.TEL}}<br>

                    <div class="not-underline hover-underline p-line" v-html="webConfig.Version">{{webConfig.Version}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import EventNameEnum from "./enum/EventName";

    const arr=[["bottom_gywm_o.png","bottom_gywm_w.png"],
        ["bottom_cpjs_o.png","bottom_cpjs_w.png"],
        ["bottom_jrwm_o.png","bottom_jrwm_w.png"],
        ["bottom_lxwm_o.png","bottom_lxwm_w.png"]];

    let bottomicons={};
    for(let i=0;i<arr.length; i++){
        bottomicons["icon"+(i+1)]={};
        for(let j=0;j<arr[i].length;j++){
            bottomicons["icon"+(i+1)]["url"+(j+1)] = require("./assets/images/public/"+arr[i][j]);
        }
    }

    export default {
        data(){
            return{
                declarationConfig:{
                    1:{switchNum:2},
                    2:{switchNum:2},
                    3:{switchNum:2}
                },
                lookbar:true,
                bottomicon:bottomicons,
                mainSwiperOptions:{
                    loop:true,
                    autoplay: {
                        delay: 3000,//3秒切换一次
                        disableOnInteraction:false,//用户操作以后关闭autoplay
                    },
                    spaceBetween: 30,
                    effect: "fade",
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    pagination: {
                        el: '.swiper-pagination',
                    },
                },
                webConfig:{
                    HealthyNotice:"健康游戏公告",
                    Address:"公司地址",
                    TEL:"投诉电话",
                    Version:"版属",
                    mainSwiper:[],
                    barIsPlayVideo:"",
                    mainVideo:"",
                    videoBg:"",
                    licenseName:"",
                    licenseImg:"",
                    copyright:"",
                }
            }
        },methods:{
            bottomIconHandle:function (e) {
                let index = Number(e.target.id.slice(10,11));
                if(index>4||index<1){
                    return;
                }
                if(e.type=="mouseover"){
                    // this.bottomiconarr[index] = true;
                    e.target.childNodes[0].src = this.bottomicon["icon"+index]["url2"];
                }else{
                    e.target.childNodes[0].src = this.bottomicon["icon"+index]["url1"];
                    // this.bottomiconarr[index] =false;
                }
            }
        },created:function() {
            document.title = "朱雀网络"
            this.$event.$on(EventNameEnum.changeBar,(value)=>{
                this.lookbar = value;
            });
            this.$http.get('declaration/getAllDeclaration',{},{})
                .then((res)=>{
                    if(res.body.code==200){
                        this.declarationConfig = res.body.data;
                    }else{

                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            this.$http.get('webConfig/getWebConfig',{params: {type:1}},{})
                .then((res) => {
                    if(res.body.code==200){
                        for(let data of res.body.data){
                            if(data.attrType===4){
                                this.webConfig[data.name] = data.imgArr;
                            }else{
                                this.webConfig[data.name] = data.content;
                            }
                        }
                    }else{

                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
        }
    }
</script>
<style>

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-button-prev:after, .swiper-button-next:after {

        font-size: 2rem!important;

    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
    }

    .swiper-button-next {
        /*right: 10rem;*/
        /*left: auto;*/
        height: 6.2rem!important;
        width: 6.2rem!important;
        border-radius:3.1rem;
        color: #ffffff!important;
        background-color: rgba(0, 0, 0, .5);
    }
    .swiper-button-prev{
        /*left: 10rem;*/
        /*right: auto;*/
        height: 6.2rem!important;
        width: 6.2rem!important;
        border-radius:3.1rem;
        color: #ffffff!important;
        background-color: rgba(0, 0, 0, .5);
    }

    #app{
        font-family: "黑体", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        position: relative;
        width: 100%;
    }

    .main-content-width {
        width: 120rem;
    }

    #s-nav-top {
        z-index: 9999;
        height: 8.3rem;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        top: 0;
        margin-right: 0;
        margin-left: 0;
    }

    #s-nav-top a {
        font-weight: bold;
        color: white;
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.4rem;
        min-width: 8.3rem;
    }

    #s-nav-top a.router-link-exact-active {
        background-color: #f66320;
        color: #fff;
    }

    html {
        font-size: 10px;
        min-width: 120rem;
        box-sizing: border-box;
    }

    body, div, span, a {
        box-sizing: border-box;
        margin: 0;
    }

    .nav-content {
        height: 100%;
        display: flex;
        margin: 0 auto;
    }

    .nav-content a{
        text-decoration:none;
    }

    .flex-sub {
        flex: 1;
    }

    .marign-center{
        margin: 0 auto;
    }

    .text-left {
        text-align: left;
    }

    .logo {
        padding-top: 1rem;

    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }
    .top-swipe {
        /*top: 0;*/
        /*width: 192rem;*/
        /*height: 57.6rem;*/
        min-width: 130rem;
        max-width: 192rem;
        overflow: hidden;
        margin: 0 auto;
    }
    .padding-sm {
        padding: 1rem;
    }

    .title-img img{
        padding-top: 4.4rem;
        margin-bottom: 4.4rem;
    }

    #bottom-nav{
        width: 100%;
        height: 11.1rem;
        background-color: #555555;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #bottom-nav div{
        /*width: 20rem;*/
        /*background-color: #a5673f;*/
        line-height: 15.1rem;
        align-items: center;
        display: flex;

    }

    .bottom-icon{
        /*display: inline-block;*/
        width: 7rem !important;
        height: 7rem;
        border-radius: 50%;
        border: .3rem solid #f66320;
        /*background-color: #f66320;*/
    }

    .bottom-icon:hover{
        background-color: #f66320;
    }

    .bottom-icon img{
        margin: 0 auto;
    }

    .bottom-text{
        width: 10rem;
        padding-left: 1rem;
    }


    .bottom-text span{
        color: #fff;
        font-family: "微软雅黑";
        font-size: 1.6rem;
    }

    #bottom-notice{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 10rem;
        width: 120rem;
        font-size: 1.3rem;
        line-height: 2rem;
        font-family: "微软雅黑";
        text-align: left;
        margin: 1rem auto 0 auto;
        /*background-color: #a5673f;*/
    }

    #bottom-notice .notice-word{
        display: inline-block;
        margin: 0 auto;
    }
    #bottom-notice .notice-logo{
        margin: 1.5rem 1rem;
    }

    #bottom-notice label span{
        font-weight: bold;
    }

    .changeBottomBtnRate{
        max-width: 50%;
        max-height: 50%;
    }

    .barVideo{
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #d2f1f0;
        width: 100%;
        height: 100%;
        padding-top: 10rem;
    }

    #bottom{
        margin: 0 auto;
    }

    .not-underline a{
        text-decoration: none;
    }

    .hover-underline a:hover{
        text-decoration:underline;
    }
    .p-line p{
        line-height: 2rem !important;
        margin: 0 auto;
    }
</style>
