import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueResource from 'vue-resource'


import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Clipboard from 'vue-clipboard2';
Vue.use(Clipboard);

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify)

import VueCoreVideoPlayer from 'vue-core-video-player';
Vue.use(VueCoreVideoPlayer,{
  lang: 'zh-CN'
})

import 'swiper/css/swiper.min.css'
import {uploadFileUrl} from "./tool/uploadFileUrl";

Vue.use(VueAwesomeSwiper, /* { default global options } */)
/*使用VueResource插件*/
Vue.use(VueResource)
// 设置全局http请求根路径[最后不带/，在请求时之前也不能带/]
let useUrl = new uploadFileUrl().url;
Vue.http.options.root = useUrl;
Vue.config.productionTip = false

Vue.prototype.$event = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$uploadFileUrl = useUrl;




