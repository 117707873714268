export class uploadFileUrl{
    constructor(){
        this._url="";
        this.init();
    }



    init(){
       // this._url = process.env.NODE_ENV === "development"?"http://localhost:3001":"";
        this._url = process.env.NODE_ENV === "development"?" http://192.168.250.153:3001":"";
    }

    get url(){
        return this._url;
    }
}
